

window.jQuery = window.$ = require('jquery');

window.jQuery.greeting = 'Hello there!';

require('popper.js');
require('bootstrap');
// require('owl.carousel');
require('./main-menu/garvan.js');
require('./common.js');

require('./easyResponsiveTabs.js');
require('./jquery.magnific-popup.min.js');
require('./jquery.form.min.js');
window.FormLoader = require('./form-loader.js');


require('../less/index.less');