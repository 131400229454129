const jQuery = require('jquery');


jQuery( function ( $ ) {
    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        var params = Array();
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                params.push(decodeURIComponent(pair[1].replace('+', ' ')));
            }
        }
        return params;
    }

    // STICKY MENU
    var cScrollToTop, currentScrollTop = 0, navbar = $('.header-navigation');
    $(window).scroll(function () {
        var scrollToTop = $(window).scrollTop();
        var headerNavHeight = navbar.height();

        currentScrollTop = scrollToTop;

        if(cScrollToTop < currentScrollTop && scrollToTop > headerNavHeight + headerNavHeight) {
            navbar.addClass("scrollUp");
        } else if(cScrollToTop > currentScrollTop && !(scrollToTop <= headerNavHeight)) {
            navbar.removeClass("scrollUp");
        }
        cScrollToTop = currentScrollTop;
    });

    var is_mobile = (window.innerWidth <= 1020);

    // Handling for site section dropdowns and header search button
    // var event = ('ontouchstart' in window) ? 'click' : 'mouseenter mouseleave';

    var event = is_mobile ? 'click' : 'mouseenter mouseleave';


    // the nav-caret is only visible on mobile, but we set the event handler anyway
    // so it works when resizing the window
    $('body')
    .on(event, '.nav-caret', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var target_sel = $(this).attr('rel'),
            $target = $(target_sel),
            alreadyExpanded = $target.parent('li').hasClass('expanded');

        $('#section-nav .nav-tabs .expanded').removeClass('expanded');
        if (!alreadyExpanded) {
            $target.parent('li').addClass('expanded');
        }

        // ,
        //     $others = $( '#section-nav .section' )
        //                  .filter( ':visible' )
        //                  .filter( ':not( ' + target_sel + ' )' );

        // // Slide up any other dropdowns
        // if( $others.length > 0 ) {
        //     // $target.hide('slow');
        //     $others.hide();
        //     // $.each($others, function(idx) {
        //     //     $(this).find('.nav-caret > img').attr('src', '++theme++garvan.theme/images/down-carat.png');
        //     // });
        // }

        // // Slide down the target dropdown
        // if ( $target.filter( ':visible' ).length == 0 ) {
        //     // $target.show('slow');
        //     $target.show();
        // } else {
        //     // $target.hide('slow');
        //     $target.hide();
        // }
    });


    $('.mobile-tab-home').off('click').on('click', function (e) {
        $('#section-nav').slideUp();
        $('#section-search').slideUp();

    });

    console.log('SETTING EVENT HANDLERS');
    $('.mobile-tab-nav').off('click').on('click', function (e) {
        console.log('MOBILE-TAB-NAV CLICK');
        $('#section-search').slideUp();
        $('#section-nav').slideToggle();
        e.preventDefault();
        return false;
    });

    $('.mobile-tab-search').off('click').on('click', function (e) {
        $('#section-nav').slideUp();
        $('#section-search').slideToggle();
        $('#mobile-search').focus();
        e.preventDefault();
        return false;
    });


    if (is_mobile) {
        console.log('mobile detected, DOING NOTHING');

        /*
        if($('body').hasClass('is-frontpage') || $('body').hasClass('subsection-research-landing-page')) {
            $('#menu-collapse').hide();
        } */


        // See #14960 - we want to hide the navigation menu if there are no items in it
        // Hiding the menu pushes the .bodycontent up and it has a negative padding
        // so it obscures the top menu.
        if( !$('.portletNavigationTree .portletContent').length ) {
            $('#menu-collapse').hide();
            $('.bodycontent').css('margin-top', 0);
        }

        // $('#section-nav ul.nav').hide();

        /*
        $('.burger').click(function(ev) {
           if ($(this).hasClass('expanded')) {
             $(this).removeClass('expanded');
             $('#section-nav-wrapper').removeClass('nav-expanded');
             $('#section-nav ul.nav').slideUp();
           } else {
             $(this).addClass('expanded');
             $('#section-nav-wrapper').addClass('nav-expanded');
             $('#section-nav ul.nav').slideDown();
           }
        });
        */
        $(".donate-dropdown > .donate-btn").attr("href", "javascript:;");
    } else {
        console.log('not mobile');
        $('body')
        .on( event, '#section-nav > ul > li > a', function ( e ) {
            e.preventDefault();
            e.stopPropagation();

            var target_sel = $( this ).attr( 'rel' ),
                $target = $( target_sel ),
                $others = $( '#section-nav .section' )
                             .filter( ':visible' )
                             .filter( ':not( ' + target_sel + ' )' );

            if (!$target.length && event == 'click') {
                window.location.href = $(this).attr('href');
            }

            // Slide up any other dropdowns
            if( $others.length > 0 ) {
                $others.hide();
            }

            // Slide down the target dropdown
            if ( $target.filter( ':visible' ).length == 0 ) {
                $target.show();
            }
        } )
        .on( 'mouseleave', '#section-nav-wrapper', function ( e ) {
            $( '#section-nav .section' ).hide();
            e.stopPropagation();
        } )

        // floating menu
        var $menu = $('#main-header'),
            $window = $(window),
            offset = $menu.offset(),
            topPadding = 15;

        $window.scroll(function() {
            if ($window.scrollTop() > offset.top + 150) {
                $menu.addClass('section-nav-fixed');
                $('body').addClass('bodyPadding');
            } else {
                $menu.removeClass('section-nav-fixed');
                $('body').removeClass('bodyPadding');
            }
        });

    }



    /// BOTH MOBILE AND DESKTOP
    $( 'body' )
    .on( 'click', '#main-header .desktop-search-container .search a', function ( e ) {
        // Initiate the search
        e.preventDefault();
        e.stopPropagation();

        var $form = $('#main-header .desktop-search-container .btn.search form');

        $form.submit();
    } )
    .on( 'click', '#main-header .desktop-search-container .search', function ( e ) {
        // Slide search form in/out
        e.preventDefault();
        e.stopPropagation();

        var $btn = $( this );

        if ( ! $btn.hasClass( 'expanded' ) )
        {
            $btn.addClass( 'expanded' ).find( 'form' ).animate( {
                width: 250
            }, function () {
                $btn.find( 'input' ).focus();
            } );
        }
    } )
    .on('click', '#expand-search', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var $btn = $('#main-header .desktop-search-container .search');
        if ( ! $btn.hasClass( 'expanded' ) )
        {
            $btn.addClass( 'expanded' ).find( 'form' ).animate( {
                width: 250
            }, function () {
                $btn.find( 'input' ).focus();
            } );
        }
        return false;
    })
    .on('focus', '#expand-search', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var $btn = $('#main-header .desktop-search-container .search');
        if ( ! $btn.hasClass( 'expanded' ) )
        {
            $btn.addClass( 'expanded' ).find( 'form' ).animate( {
                width: 250
            }, function () {
                $btn.find( 'input' ).focus();
            } );
        }
        return false;
    })
    .on('blur', '#sitesearch', function(e) {
        $( '#main-header .desktop-search-container .search.expanded form' ).animate( {
            width: 0,
        }, function () { $(this).parent().removeClass('expanded'); } );
    })
    .on( 'click', function ( e ) {
        // Slide any dropdowns up and slide in the search form when clicking outside
        $( '#section-nav .section' ).hide();
        $( '#main-header .desktop-search-container .search.expanded form' ).animate( {
            width: 0,
        }, function () { $(this).parent().removeClass('expanded'); } );
    } );


    if ($('body').hasClass('subsection-research-landing-page')) {
        if ($('#portletPageBottomRow > div').length > 3 ) {
            var rows = Array();
            $('#portletPageBottomRow > div').each(function(idx, val) {
                var row = $('<div>', {'id': 'portletPageBottomBlock' + idx, 'class': 'bottom-portlet'});
                $(this).appendTo(row);
                if(idx == 0) {
                    var newrow
                   $('#portletPageBottomRow').after('<div>');
                } else {
                    $('#portletPageBottomBlock' + (idx-1)).after(row);
                }
            });
        }
    }

   if ($('body').hasClass('section-frontpage')) {
    if ($('#portletPageTopRow').length > 0) {
        $('#portletPageTopRow').appendTo('#topportlet .container');
    }
    if ($('#column-collective-portletpage-toprow-portlet-top-row-stretch').length > 0) {
        $('#column-collective-portletpage-toprow-portlet-top-row-stretch').appendTo('#midportlet .container');
    }
    $('#menucollapse').hide();
    $('h1.documentFirstHeading').hide();
    }

    $('div.mce_inline_error').hide();

   // search filter fix
   $('#searchform .actionMenu').remove();
   $('#searchform .input-group').after('<div id="search-filter"><fieldset class="noborder"><div style="float: left;margin-right: 20px;margin-top: 1px;font-size: 20px;color: #003263;/*font-family: EBGaramond, serif;*/">Filter</div><div class="field"><div class="search-type-options"><div><input type="checkbox" name="portal_type:list" value="Document" class="noborder" checked="" id="portal_type_9"><label for="portal_type_9">Page</label></div><div><input type="checkbox" name="portal_type:list" value="News Item" class="noborder" checked="" id="portal_type_11"><label for="portal_type_11">News Item</label></div><div><input type="checkbox" name="portal_type:list" value="garvan.types.publication" class="noborder" checked="" id="portal_type_13"><label for="portal_type_13">Publication</label></div><div><input type="checkbox" name="portal_type:list" value="garvan.types.person" class="noborder" checked="" id="portal_type_14"><label for="portal_type_14">Person</label></div><div><input type="checkbox" name="portal_type:list" value="garvan.types.research_group" class="noborder" checked="" id="portal_type_16"><label for="portal_type_16">Lab</label></div><div><input type="checkbox" name="portal_type:list" value="garvan.types.newsletter" class="noborder" checked="" id="portal_type_19"><label for="portal_type_19">Newsletter</label></div></div></div></fieldset></div>');

   var url_params = getQueryVariable('portal_type:list');
   console.log(url_params);
   $('#searchform input[type="checkbox"]').prop('checked', false);
   $('#searchform input[type="checkbox"]').each(function (idx, val) {
       console.log($(this).val());
       if($.inArray($(this).val(), url_params) !== -1) {
         $(this).prop('checked', 'checked');
       }
   });


    $('.video-popup').magnificPopup({type:'iframe'});
});