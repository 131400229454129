
const $ = require('jquery');

// JavaScript Document

/*$(document).ready(function(){
  $('.sticky').stickMe();
})
*/

/*
$(".header-search-icon").click(function(e) {
        $(".search-form-wrapper").toggleClass("show-search");
   });
  $('body').click(function(evt){
    if($(evt.target).closest('.header-search-icon, .search-form-wrapper').length)
    return;
    $(".search-form-wrapper").removeClass("show-search");
  });
  $(".search-close").click(function(e) {
    $(".search-form-wrapper").removeClass("show-search");
  });







$(".animated-arrow").click(function(e) {
	$("body").toggleClass("tsla-prevent-scroll");
});
$('body').click(function(evt){
	if($(evt.target).closest('.animated-arrow').length)
	return;
	$("body").removeClass("tsla-prevent-scroll");
});


$(".wsmenu-list li").click(function(e) {
	$("body").toggleClass("overflowbody");
});
$('body').click(function(evt){
	if($(evt.target).closest('.wsmenu-list li').length)
	return;
	$("body").removeClass("overflowbody");
});


*/




$(document).ready(function() {
	$(".menubtn") .click(function (){
		 $(this).toggleClass('menucurrent');
	$(".showmenulist").fadeToggle(300);
	});
});


var new_scroll_position = 0;
var last_scroll_position;
var header = document.getElementById("headermenusticky");

window.addEventListener('scroll', function (e) {

  if (!header) { return; }
  last_scroll_position = window.scrollY;

  // Scrolling down
  if (new_scroll_position < last_scroll_position && last_scroll_position > 110) {
    // header.removeClass('slideDown').addClass('slideUp');
    header.classList.remove("slideDown");
    header.classList.add("slideUp");

  // Scrolling up
  } else if (new_scroll_position > last_scroll_position) {
    // header.removeClass('slideUp').addClass('slideDown');
    header.classList.remove("slideUp");
    header.classList.add("slideDown");
  }

  new_scroll_position = last_scroll_position;
});



