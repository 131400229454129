var FormLoader = (function() {
	var formUrl = "";
	var formSelector = "";
	var formMethod = "";
	var formAction = "";
	var isFormLoaded = false;
	var successMessage;

	return {
		/*
		option = {
			form_url: (required),
			success_message: (optional)
		}
		*/
		init: function(formSelector, option) {
			if(!FormLoader.validateFormSelector(formSelector)) { return; }
			if(!FormLoader.validateOption(option)) { return; }
			if(!FormLoader.validateFormUrl(option)) { return; }

			if(option.hasOwnProperty('success_message')) {
				FormLoader.successMessage = option.success_message;
			} else {
				FormLoader.successMessage = "";
			}

			FormLoader.formSelector = formSelector;
			FormLoader.loadForm();
			FormLoader.isFormLoaded();
		}, 
		validateFormSelector: function(formSelector) {
			if(typeof formSelector === "undefined") {
				FormLoader.logMessage('formSelector is required.');
				return false;
			} else if($(formSelector).length == 0) {
				FormLoader.logMessage('formSelector not found.');
				return false;
			}
			return true;
		},
		validateOption: function(option) {
			if(!option) {
				FormLoader.logMessage('Option is required!');
				return false;
			} else if(typeof option !== "object") {
				FormLoader.logMessage('Invalid option format.');
				return false;
			}
			return true;
		},
		validateFormUrl: function(option) {
			if(!option.hasOwnProperty('form_url')) {
				FormLoader.logMessage('\'form_url\' is required!');
				return false;
			}

			formUrl = option.form_url;
			return true;
		},
		loadForm: function() {
			$(FormLoader.formSelector).html('Loading form . . .');
			var lf = $.get(formUrl, function(data) {
				$(FormLoader.formSelector).html("<div id='formMessageNotif'></div>" + data);

				$(FormLoader.formSelector + " .pfg-form input[type=button], .pfg-form input[type=submit], .pfg-form button").addClass("garvan-btn-orange");

				formMethod = $(FormLoader.formSelector + " .pfg-form form").attr("method");
				formAction = $(FormLoader.formSelector + " .pfg-form form").attr("action");
				if(formAction == "@@embedded") {
					formAction = formUrl.substring(0, formUrl.length - 10);
					$(FormLoader.formSelector + " form").attr("action", formAction);
				}

				isFormLoaded = true;
			}).fail(function() {
				FormLoader.logMessage("Unable to load the form!");
			});
		},
		isFormLoaded: function() {
			var checkFormLoaded = setInterval(function() {
				$(FormLoader.formSelector + " form").ajaxForm({
					success: FormLoader.processFormResult
				});
				clearInterval(checkFormLoaded);
			}, 1000);
		},
		processFormResult: function(responseText, statusText, xhr, $form) {
			var responseTextDom = new DOMParser().parseFromString(responseText, "text/html");
			contentCore = responseTextDom.getElementById('content-core').innerHTML;
			contentCoreDom = new DOMParser().parseFromString(contentCore, "text/html");
			pfgForm = contentCoreDom.getElementsByClassName('pfg-form');
			var formSelectorString = FormLoader.formSelector.substring(1);

			if(pfgForm.length != 0) {
				document.getElementById('formMessageNotif').innerHTML = "Form submission failed!";
			} else {
				var notifWrapper = document.getElementById('formMessageNotif');
				if(FormLoader.successMessage != "") {
					notifWrapper.innerHTML = FormLoader.successMessage;
					notifWrapper.classList.add('success');

					var objFormWRapper = document.getElementById(formSelectorString);
					objFormWRapper.getElementsByClassName('pfg-form')[0].innerHTML = "";
				} else {
					document.getElementById(formSelectorString).innerHTML = contentCore;
				}
			}
		},
		logMessage: function(errorMessage) {
			console.log("FORM LOADER: " + errorMessage);
		}
	};
})();

module.exports = FormLoader